import { FC } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";

import { GoogleButton, GradientButton, MuiButton, MuiDialog, TextInput } from "@causevest/ui-kit";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Stack, capitalize } from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import * as yup from "yup";

import { useAuth } from "@features/auth";

import { REQUIRED_MESSAGE } from "@lib/constants";
import commonClasses from "@lib/styles/common.module.scss";

import classes from "./LogInModal.module.scss";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSignUp: () => void;
  onResetPassword: () => void;
}

const schema = yup.object().shape({
  email: yup.string().email().required("Email is a required field"),
  password: yup.string().required(REQUIRED_MESSAGE),
});

export const LogInModal: FC<Props> = ({ isOpen, onClose, onSignUp, onResetPassword }) => {
  const { login } = useAuth();
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSignUpHandle = () => {
    onSignUp();
    onClose();
  };

  const onHandleSubmit = async (values: FieldValues) => {
    onClose();
    await login(values);
  };

  const onSocialAuth = useGoogleLogin({
    ux_mode: "redirect",
    redirect_uri: `${process.env.ORIGIN_URL}/auth/callback/google`,
    flow: "auth-code",
  });

  return (
    <MuiDialog title="Log in with social or email" isOpen={isOpen} handleClose={onClose} isCentered>
      <FormProvider {...methods}>
        <Stack className={classes.wrapper}>
          <Box className={classes.googleBtn}>
            <GoogleButton onClick={onSocialAuth} />
          </Box>
          <Stack
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            className={classes.question}
          >
            <span>Don&apos;t have an account?</span>
            <MuiButton
              variant="text"
              color="primary"
              className={classes.signup}
              onClick={onSignUpHandle}
              disableRipple
            >
              Sign Up
            </MuiButton>
          </Stack>
          <Stack className={classes.inputs}>
            <TextInput
              name="email"
              type="email"
              label="E-Mail Address"
              className={commonClasses.input}
              error={!!errors.email}
              errorMessage={errors.email?.message ? capitalize(errors.email.message) : undefined}
              fullWidth
              required
            />
            <TextInput
              name="password"
              type="password"
              label="Password"
              className={commonClasses.input}
              error={!!errors.password}
              errorMessage={
                errors.password?.message ? capitalize(errors.password.message) : undefined
              }
              fullWidth
              required
            />
          </Stack>
          <MuiButton
            variant="text"
            color="primary"
            className={classes.forgotPass}
            onClick={onResetPassword}
            disableRipple
          >
            Forgot your password?
          </MuiButton>
          <GradientButton
            onClick={handleSubmit(onHandleSubmit)}
            className={classes.loginBtn}
            fullWidth
          >
            Login
          </GradientButton>
          <Box className={classes.info}>
            Causevest is a way to bring the causes you love to life. Causevest does not guarantee
            campaigns or investigate a creator&apos;s ability to complete their project. It is the
            responsibility of the project creator to complete their project as promised, and the
            claims of this project are theirs alone.
          </Box>
        </Stack>
      </FormProvider>
    </MuiDialog>
  );
};
