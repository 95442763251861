import {
  AdvancedAxiosConfig,
  CategoriesList,
  ClientIdEntity,
  Country,
  Currency,
  TrustedList,
} from "@lib/types";

import { apiGateway } from "./gateway";

export const commonAPI = {
  getSettings: async (options?: AdvancedAxiosConfig) => {
    const { data: res } = await apiGateway.get<{ data: ClientIdEntity[] }>(
      "/settings?filter[key]=front_end_client_id,front_end_client_secret",
      options,
    );

    return res.data;
  },
  getTrusted: async (options?: AdvancedAxiosConfig) => {
    const { data: res } = await apiGateway.get<TrustedList>("/businesses/trusted", options);

    return res;
  },
  getCategories: async (options?: AdvancedAxiosConfig) => {
    const { data: res } = await apiGateway.get<CategoriesList>("/categories", options);

    return res;
  },
  getCountriesList: async (options?: AdvancedAxiosConfig) => {
    const { data: res } = await apiGateway.get<{ data: Country[] }>("/countries", options);

    return res.data;
  },
  getCurrencies: async (options?: AdvancedAxiosConfig) => {
    const { data: res } = await apiGateway.get<{ data: Currency[] }>("/currencies", options);

    return res.data;
  },
};
