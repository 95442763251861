import { parseQueryFilters } from "@lib/helpers";
import {
  AdvancedAxiosConfig,
  Campaign,
  CampaignsList,
  Goal,
  ListFilters,
  NewCampaign,
  Tier,
} from "@lib/types";

import { apiGateway } from "./gateway";

const API_URL_PREFIX = "/campaigns";

export const campaignsAPI = {
  getCampaigns: async (options?: AdvancedAxiosConfig, filters?: Partial<ListFilters>) => {
    const url = parseQueryFilters(API_URL_PREFIX, filters);

    const { data: res } = await apiGateway.get<Partial<ListFilters>, { data: CampaignsList }>(
      url,
      options,
    );

    return res;
  },

  getOperatorCampaigns: async (options?: AdvancedAxiosConfig) => {
    return await apiGateway.get<{ data: CampaignsList }>(`${API_URL_PREFIX}/operator`, options);
  },

  getFundraiserCampaigns: async (options?: AdvancedAxiosConfig) => {
    return await apiGateway.get<{ data: CampaignsList }>(
      `${API_URL_PREFIX}/created-for-you`,
      options,
    );
  },

  getCampaign: async (campaignId: string, options?: AdvancedAxiosConfig) => {
    const { data: res } = await apiGateway.get<{ data: Campaign }>(
      `${API_URL_PREFIX}/${campaignId}`,
      options,
    );

    return res.data;
  },

  createNewCampaign: async (body: Partial<NewCampaign>, options?: AdvancedAxiosConfig) => {
    const { data: res } = await apiGateway.post<
      Partial<NewCampaign>,
      { data: { data: { uuid: string } } }
    >(`${API_URL_PREFIX}/all-in-one`, body, options);

    return res.data;
  },

  updateCampaign: async ({ uuid, ...body }: Partial<Campaign>, options?: AdvancedAxiosConfig) => {
    return await apiGateway.patch<Partial<NewCampaign>, void>(
      `${API_URL_PREFIX}/${uuid}`,
      body,
      options,
    );
  },

  createCampaignGoal: async (body: Tier, options?: AdvancedAxiosConfig) => {
    return await apiGateway.post<Tier, void>("/campaign-goals", body, options);
  },

  updateCampaignGoal: async ({ uuid, ...body }: Partial<Goal>, options?: AdvancedAxiosConfig) => {
    return await apiGateway.patch<Partial<NewCampaign>, void>(
      `/campaign-goals/${uuid}`,
      body,
      options,
    );
  },
};
