import { AdvancedAxiosConfig, NewRewardParams, Reward } from "@lib/types";

import { apiGateway } from "./gateway";

const API_PREFIX = "/rewards";

export const rewardsAPI = {
  getRewards: async (options?: AdvancedAxiosConfig) => {
    const { data: res } = await apiGateway.get<Reward[]>(API_PREFIX, options);

    return res;
  },

  getReward: async (uuid: string, options?: AdvancedAxiosConfig) => {
    const { data: res } = await apiGateway.get<Reward>(`${API_PREFIX}/${uuid}`, options);

    return res;
  },

  createReward: async (body: NewRewardParams, options?: AdvancedAxiosConfig) => {
    const res = await apiGateway.post<NewRewardParams, { data: { data: Reward } }>(
      API_PREFIX,
      body,
      options,
    );

    return res.data;
  },

  updateReward: async (
    { uuid, ...body }: Partial<NewRewardParams>,
    options?: AdvancedAxiosConfig,
  ) => {
    await apiGateway.patch<Partial<NewRewardParams>>(`${API_PREFIX}/${uuid}`, body, options);
  },

  deleteReward: async ({ uuid }: { uuid: string }, options?: AdvancedAxiosConfig) => {
    await apiGateway.delete<Partial<NewRewardParams>>(`${API_PREFIX}/${uuid}`, options);
  },
};
