"use client";

import { FC, ReactElement, createContext, useCallback, useContext, useReducer } from "react";

import persistentStore from "store2";

import { User } from "@lib/types";

interface Props {
  children: ReactElement | ReactElement[];
}

interface UserChangeAction {
  payload?: Partial<User>;
}

interface ReturnType {
  setUserData: (data: Partial<User>) => void;
  resetUserData: () => void;
  user?: Partial<User>;
}

const defaultChangeFunction = () => console.warn("Initialize User Context Provider first");

const initialState: Partial<User> = persistentStore.get("user") ?? {};

function userReducer(state: Partial<User>, action: UserChangeAction): Partial<User> {
  if (action.payload) {
    return { ...state, ...action.payload };
  } else {
    return initialState;
  }
}

export const SessionContext = createContext<ReturnType>({
  ...initialState,
  setUserData: defaultChangeFunction,
  resetUserData: defaultChangeFunction,
});

export const useSession = (): ReturnType => useContext(SessionContext);

export const SessionProvider: FC<Props> = ({ children }) => {
  const [user, dispatch] = useReducer(userReducer, initialState);

  const resetUserData = useCallback(() => {
    persistentStore.clearAll();
    dispatch({ payload: undefined });
  }, []);

  const setUserData = useCallback((payload: Partial<User>) => {
    dispatch({ payload });
    persistentStore.add("user", payload);
  }, []);

  return (
    <SessionContext.Provider value={{ user, setUserData, resetUserData }}>
      {children}
    </SessionContext.Provider>
  );
};
