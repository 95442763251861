export enum AppRoutes {
  MAIN = "/",
  CAMPAIGNS = "/campaigns",
  LEADERBOARD = "/leaderboard",
  PROFILE = "/profile",
  DASHBOARD = "/dashboard",
}

export enum UserTypes {
  DONOR = "donor",
  RAISER = "fundraiser",
}

export enum CampaignGoal {
  FINANCIAL = "financial",
  OPEN = "open-ended",
  TIMED = "timed",
}

export enum CampaignGoalTypes {
  MONETARY = "monetary",
  SUPPORT = "support",
}

export enum DeliveryType {
  PHYSICAL = "physical",
  DIGITAL = "digital",
}

export enum FAQType {
  CAMPAIGN = "campaign",
  THREAD = "thread",
  USER = "user",
  BUSINESS = "business",
  CAUSE = "cause",
}
