import {
  AdvancedAxiosConfig,
  CreateThreadBody,
  Thread,
  ThreadCommentBody,
  ThreadReportBody,
  ThreadsList,
  UpdateCommentBody,
} from "@lib/types";

import { apiGateway } from "./gateway";

const THREADS_URL_PREFIX = "/threads";

export const threadAPI = {
  getThreadsList: async (options?: AdvancedAxiosConfig) => {
    const { data: res } = await apiGateway.get<ThreadsList>(THREADS_URL_PREFIX, options);

    return res;
  },

  getThread: async (id: string, options?: AdvancedAxiosConfig) => {
    const { data: res } = await apiGateway.get<{ data: Thread }>(
      `${THREADS_URL_PREFIX}/${id}`,
      options,
    );

    return res.data;
  },

  createThread: async (body: CreateThreadBody, options?: AdvancedAxiosConfig) => {
    await apiGateway.post<CreateThreadBody, void>(THREADS_URL_PREFIX, body, options);
  },

  updateThread: async ({ uuid, ...body }: CreateThreadBody, options?: AdvancedAxiosConfig) => {
    await apiGateway.patch<CreateThreadBody, void>(`${THREADS_URL_PREFIX}/${uuid}`, body, options);
  },

  deleteThread: async ({ uuid }: { uuid: string }, options?: AdvancedAxiosConfig) => {
    await apiGateway.delete<{ uuid: string }, void>(`${THREADS_URL_PREFIX}/${uuid}`, options);
  },

  createThreadComment: async (
    { thread, ...body }: ThreadCommentBody,
    options?: AdvancedAxiosConfig,
  ) => {
    await apiGateway.post<CreateThreadBody, void>(
      `${THREADS_URL_PREFIX}/${thread}/comments`,
      body,
      options,
    );
  },

  getThreadComment: async ({ comment }: { comment: string }, options?: AdvancedAxiosConfig) => {
    await apiGateway.get<Comment>(`${THREADS_URL_PREFIX}/comment/${comment}`, options);
  },

  updateThreadComment: async (
    { comment, ...body }: UpdateCommentBody,
    options?: AdvancedAxiosConfig,
  ) => {
    await apiGateway.patch<UpdateCommentBody, void>(
      `${THREADS_URL_PREFIX}/comment/${comment}`,
      body,
      options,
    );
  },

  deleteThreadComment: async ({ comment }: { comment: string }, options?: AdvancedAxiosConfig) => {
    await apiGateway.delete(`${THREADS_URL_PREFIX}/comment/${comment}`, options);
  },

  toggleHidingThreadComment: async (
    { comment }: { comment: string },
    options?: AdvancedAxiosConfig,
  ) => {
    await apiGateway.post(`${THREADS_URL_PREFIX}/comment/${comment}/hide`, options);
  },

  toggleThreadVisibility: async ({ thread }: { thread: string }, options?: AdvancedAxiosConfig) => {
    await apiGateway.post(`${THREADS_URL_PREFIX}/${thread}/hide`, options);
  },

  toggleThreadLike: async ({ thread }: { thread: string }, options?: AdvancedAxiosConfig) => {
    await apiGateway.post(`${THREADS_URL_PREFIX}/${thread}/like`, undefined, options);
  },

  toggleThreadLock: async ({ thread }: { thread: string }, options?: AdvancedAxiosConfig) => {
    await apiGateway.post(`${THREADS_URL_PREFIX}/${thread}/lock`, options);
  },

  toggleThreadPin: async ({ thread }: { thread: string }, options?: AdvancedAxiosConfig) => {
    await apiGateway.post(`${THREADS_URL_PREFIX}/${thread}/pin`, options);
  },

  claimThread: async ({ thread, reason }: ThreadReportBody, options?: AdvancedAxiosConfig) => {
    await apiGateway.post(`${THREADS_URL_PREFIX}/${thread}/report`, { reason }, options);
  },
};
