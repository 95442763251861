import { AdvancedAxiosConfig, FAQ, FAQDeleteParams, FAQPostBody, FAQUnitBody } from "@lib/types";

import { apiGateway } from "./gateway";

const API_PREFIX = "/faqs";

export const faqAPI = {
  createFAQsList: async (body: FAQUnitBody, options?: AdvancedAxiosConfig) => {
    await apiGateway.post<FAQUnitBody, void>(API_PREFIX, body, options);
  },

  getFAQsList: async (faq: string, options?: AdvancedAxiosConfig) => {
    await apiGateway.get<{ data: FAQ[] }>(`${API_PREFIX}/${faq}`, options);
  },

  deleteFAQsList: async (faq: string, options?: AdvancedAxiosConfig) => {
    await apiGateway.delete(`${API_PREFIX}/${faq}`, options);
  },

  restoreFAQsList: async (faq: string, options?: AdvancedAxiosConfig) => {
    const res = await apiGateway.post<void, { data: FAQ[] }>(
      `${API_PREFIX}/${faq}/restore`,
      options,
    );

    return res.data;
  },

  createFAQ: async ({ faq, ...body }: FAQPostBody, options?: AdvancedAxiosConfig) => {
    const res = await apiGateway.post<FAQPostBody, { data: FAQ }>(
      `${API_PREFIX}/${faq}/questions`,
      body,
      options,
    );

    return res.data;
  },

  updateFAQ: async ({ questionId, ...body }: FAQPostBody, options?: AdvancedAxiosConfig) => {
    const res = await apiGateway.put<FAQPostBody, { data: FAQ }>(
      `${API_PREFIX}/questions/${questionId}`,
      body,
      options,
    );

    return res.data;
  },

  deleteFAQ: async ({ question }: FAQDeleteParams, options?: AdvancedAxiosConfig) => {
    await apiGateway.delete(`${API_PREFIX}/questions/${question}`, options);
  },

  restoreFAQ: async (question: string, options?: AdvancedAxiosConfig) => {
    const res = await apiGateway.post<void, { data: FAQ }>(
      `${API_PREFIX}/questions/${question}/restore`,
      options,
    );

    return res.data;
  },
};
