"use client";

import { usePathname, useRouter } from "next/navigation";

import { ReactNode, createContext, useCallback, useContext, useState } from "react";
import { FieldValues } from "react-hook-form";
import { toast } from "react-toastify";

import { useEffectAsync } from "@causevest/ui-kit";
import { AxiosError } from "axios";

import { authAPI, proxyAPIGateway } from "@api";

import { useSession } from "@contexts";

import { handleErrorToast } from "@lib/helpers";
import { AppRoutes, ErrorFlat, ErrorUnprocessable, User } from "@lib/types";

interface AuthContextType {
  isAuthenticated: boolean;
  isLoading: boolean;
  login: (data: FieldValues) => Promise<User | void>;
  onSocialLogin: (code: string) => Promise<void>;
  register: (data: FieldValues) => Promise<void>;
  logout: () => Promise<void>;
  checkAuth: () => void;
}

interface Props {
  children: ReactNode;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export const AuthProvider = ({ children }: Props) => {
  const router = useRouter();
  const pathname = usePathname();
  const { setUserData, resetUserData } = useSession();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const register = useCallback(async ({ email, display_name, password }: FieldValues) => {
    setLoading(true);
    try {
      await authAPI.registerNewUser({
        email,
        display_name,
        password,
        password_confirmation: password,
      });

      toast.success("User was successfully created. Please, check your email", {
        theme: "colored",
      });
    } catch (err) {
      const error = err as AxiosError;
      handleErrorToast(error.response?.data as ErrorUnprocessable);
    } finally {
      setLoading(false);
    }
  }, []);

  const onSocialLogin = useCallback(
    async (code: string) => {
      setLoading(true);
      try {
        const {
          data: { user },
        } = await proxyAPIGateway.post("/auth/social", { code });

        setIsAuthenticated(true);
        setUserData(user);
        router.push(AppRoutes.DASHBOARD);
      } catch (err) {
        const error = err as AxiosError;
        handleErrorToast(error.response?.data as ErrorUnprocessable);
      } finally {
        setLoading(false);
      }
    },
    [router, setUserData],
  );

  const login = useCallback(
    async (data: FieldValues) => {
      setLoading(true);
      try {
        const {
          data: { authenticated, user },
        } = await proxyAPIGateway.post("/auth/login", data);

        setIsAuthenticated(authenticated);
        setUserData(user);
        return user;
      } catch (err) {
        handleErrorToast((err as AxiosError).response?.data as ErrorFlat);
      } finally {
        setLoading(false);
      }
    },
    [setUserData],
  );

  const logout = useCallback(async () => {
    setLoading(true);
    try {
      await proxyAPIGateway.post("/auth/logout");
      resetUserData();
      setIsAuthenticated(false);

      if (pathname.includes(AppRoutes.PROFILE) || pathname.includes(AppRoutes.DASHBOARD)) {
        router.push(AppRoutes.MAIN);
      }
    } catch (error) {
      handleErrorToast((error as Error).message as string);
    } finally {
      setLoading(false);
    }
  }, [pathname, resetUserData, router]);

  const checkAuth = useCallback(async () => {
    setLoading(true);
    try {
      const {
        data: { authenticated },
      } = await proxyAPIGateway.post("/auth/refresh");
      setIsAuthenticated(authenticated);
    } catch (error) {
      await logout();
    } finally {
      setLoading(false);
    }
  }, [logout]);

  useEffectAsync(async (awaiter) => {
    try {
      await awaiter(checkAuth());
    } catch (_) {
      resetUserData();
      setIsAuthenticated(false);
      setLoading(false);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        login,
        logout,
        register,
        checkAuth,
        onSocialLogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
