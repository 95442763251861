import { AdvancedAxiosConfig, BusinessesList } from "@lib/types";

import { apiGateway } from "./gateway";

const API_PREFIX = "/businesses";

export const businessesAPI = {
  getBusinesses: async (options?: AdvancedAxiosConfig) => {
    const { data: res } = await apiGateway.get<BusinessesList>(API_PREFIX, options);

    return res;
  },
};
