import React, { FC } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";

import { CancelButton, GradientButton, Icon, TextInput } from "@causevest/ui-kit";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Stack, Typography, capitalize } from "@mui/material";
import clsx from "clsx";
import * as yup from "yup";

import common from "@lib/styles/common.module.scss";

import classes from "./AuthForm.module.scss";

interface Props {
  onSubmit: (values: FieldValues) => void;
  onClose?: () => void;
  withInfo?: boolean;
}

const schema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character",
    ),
  confirmPassword: yup
    .string()
    .required("Please retype your password")
    .oneOf([yup.ref("password")], "Passwords must match"),
});

export const PasswordsForm: FC<Props> = ({ onSubmit, onClose, withInfo }) => {
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmitPassword = ({ password, confirmPassword }: FieldValues) => {
    if (password === confirmPassword) {
      onSubmit({ password });

      if (onClose) {
        onClose();
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <Stack className={classes.form}>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.form__inner}
        >
          <Stack gap="10px">
            <TextInput
              name="password"
              type="password"
              className={common.input}
              placeholder="Enter password"
              error={!!errors.password}
              errorMessage={
                errors.password?.message ? capitalize(errors.password.message) : undefined
              }
              required
            />
            <TextInput
              name="confirmPassword"
              type="password"
              className={clsx(common.input, common._last)}
              placeholder="Confirm password"
              error={!!errors.confirmPassword}
              errorMessage={
                errors.confirmPassword?.message
                  ? capitalize(errors.confirmPassword.message)
                  : undefined
              }
              required
            />
          </Stack>
          {withInfo && (
            <Box className={classes.form__right}>
              <Icon src="/images/bell.svg" alt="bell" width={25} height={26} />
              <Typography className={classes.form__right_text}>
                You can always change it later in the profile section.
              </Typography>
            </Box>
          )}
        </Stack>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent={onClose ? "space-between" : "flex-end"}
        >
          {!!onClose && <CancelButton onClick={onClose} />}
          <GradientButton
            onClick={handleSubmit(onSubmitPassword)}
            className={common.submitBtn}
            isArrow
          >
            Submit
          </GradientButton>
        </Stack>
      </Stack>
    </FormProvider>
  );
};
