import {
  AdvancedAxiosConfig,
  LogInResponse,
  NewUserParameters,
  ObtainTokenParams,
  RefreshTokenQueryParams,
  RefreshTokenResponse,
  ResetPassword,
  VerifyEmailData,
} from "@lib/types";

import { apiGateway } from "./gateway";

const AUTH_URL_PREFIX = "/authentication";

export const authAPI = {
  registerNewUser: async (data: NewUserParameters, options?: AdvancedAxiosConfig) => {
    const { data: res } = await apiGateway.post<NewUserParameters, { data: LogInResponse }>(
      `${AUTH_URL_PREFIX}/register`,
      data,
      options,
    );

    return res;
  },
  onUserLogin: async (data: ObtainTokenParams, options?: AdvancedAxiosConfig) => {
    const { data: res } = await apiGateway.post<
      ObtainTokenParams,
      { data: { data: LogInResponse } }
    >(`${AUTH_URL_PREFIX}/token`, data, options);

    return res.data;
  },
  refreshAuthToken: async (data: RefreshTokenQueryParams, options?: AdvancedAxiosConfig) => {
    const { data: res } = await apiGateway.post<
      RefreshTokenQueryParams,
      { data: RefreshTokenResponse }
    >(`${AUTH_URL_PREFIX}/token`, data, options);

    return res;
  },
  resetPassword: async (data: ResetPassword, options?: AdvancedAxiosConfig) => {
    await apiGateway.post<ResetPassword, void>(`${AUTH_URL_PREFIX}/reset-password`, data, options);
  },
  resetPasswordByEmail: async (
    data: Pick<ResetPassword, "email">,
    options?: AdvancedAxiosConfig,
  ) => {
    await apiGateway.post<Pick<ResetPassword, "email">, void>(
      `${AUTH_URL_PREFIX}/forgot-password`,
      data,
      options,
    );
  },
  verifyUserEmail: async ({ id, hash, ...data }: VerifyEmailData, options: AdvancedAxiosConfig) => {
    await apiGateway.post<Pick<ResetPassword, "email">, void>(
      `${AUTH_URL_PREFIX}/email/verify/${id}/${hash}`,
      data,
      options,
    );
  },
  resendEmailVerification: async (options?: AdvancedAxiosConfig) => {
    await apiGateway.post(`${AUTH_URL_PREFIX}/email/resend`, options);
  },
};
