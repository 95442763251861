import { toast } from "react-toastify";

import { AxiosError } from "axios";
import dayjs from "dayjs";

import { ErrorFlat, ErrorUnprocessable, ListFilters, User } from "@lib/types";

const SERVER_ERROR_MESSAGE = "Something went wrong on server";

export const getParsedUserName = (user?: Partial<User>) => {
  const fullName =
    user?.first_name || user?.last_name
      ? `${user?.first_name ?? ""} ${user?.last_name ?? ""}`.trim()
      : (user?.email ?? user?.name ?? "Unknown User");

  return user?.display_name ?? fullName;
};

export const getParsedDate = (date: string, divider?: string) => {
  const currentDate = new Date(date);

  if (divider) {
    return dayjs(currentDate).format(`YYYY${divider}DD${divider}MM`);
  } else {
    return dayjs(currentDate).format("DD MMM, YYYY h:mm A");
  }
};

export const setDaysGap = (date: string) => {
  const now = dayjs(new Date());

  return dayjs(date).diff(now, "day");
};

export const setCurrency = (currency?: string) => {
  switch (currency) {
    case "EUR":
      return "€";
    case "GBP":
      return "£";
    case "XCV":
      return "XCV";
    default:
      return "$";
  }
};

export const handleErrorToast = (value?: ErrorUnprocessable | ErrorFlat | string) => {
  const errorsObj = value as ErrorUnprocessable;
  const flatErrorObj = value as ErrorFlat;
  const stringError = value as string;

  if (errorsObj?.errors) {
    for (const key in errorsObj.errors) {
      errorsObj.errors[key]?.map((error) => {
        return toast.error(error, {
          theme: "colored",
        });
      });
    }
  } else if (flatErrorObj?.message) {
    return toast.error(
      flatErrorObj?.status === 500 ? SERVER_ERROR_MESSAGE : flatErrorObj?.message,
      {
        theme: "colored",
      },
    );
  } else {
    return toast.error(stringError ?? SERVER_ERROR_MESSAGE, {
      theme: "colored",
    });
  }
};

export const parseFiltersParams = (filters: Partial<ListFilters>, filtersArr: string[]) => {
  return filtersArr.map((filter) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const currentFilter = filters[filter];
    if (currentFilter) {
      switch (filter) {
        case "page":
          return `page=${filters[filter]}`;
        case "per_page":
          return `per_page=${filters[filter]}`;
        case "sort":
          if (currentFilter === "Sort By") {
            return "";
          } else {
            return `sort=${filters[filter]}`;
          }
        case "category": {
          if (currentFilter === "all" || currentFilter === "Sort By Category") {
            return "";
          } else {
            return `filter[${filter}]=${currentFilter}`;
          }
        }
        case "status": {
          if (currentFilter === "Filter by status") {
            return "";
          } else {
            return `filter[${filter}]=${currentFilter}`;
          }
        }
        case "goal": {
          if (currentFilter === "Filter by goal") {
            return "";
          } else {
            return `filter[${filter}]=${currentFilter}`;
          }
        }
        default: {
          return `filter[${filter}]=${currentFilter}`;
        }
      }
    }
  });
};

export const parseQueryFilters = (urlPrefix: string, filters?: Partial<ListFilters>) => {
  const filtersArr = filters ? Object.keys(filters) : [];
  const filtersParams = filters && filtersArr.length ? parseFiltersParams(filters, filtersArr) : [];

  return filtersParams.length
    ? `${urlPrefix}?${filtersParams.filter((val) => !!val).join("&")}`
    : urlPrefix;
};

export const handleQueryError = (err: unknown) => {
  const error = err as AxiosError;
  const reportMessage =
    error.response?.status === 500 ? "Something went wrong on server" : error.response?.data;

  return Response.json(reportMessage, {
    status: error.response?.status,
  });
};
