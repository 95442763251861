import { AdvancedAxiosConfig, Cause, CausesList } from "@lib/types";

import { apiGateway } from "./gateway";

const CAUSES_URL_PREFIX = "/causes";

export const causeAPI = {
  getCausesList: async (options?: AdvancedAxiosConfig) => {
    const { data: res } = await apiGateway.get<CausesList>(CAUSES_URL_PREFIX, options);

    return res;
  },

  createCause: async (body: Cause, options?: AdvancedAxiosConfig) => {
    const { data: res } = await apiGateway.post<{ data: { uuid: string } }>(
      CAUSES_URL_PREFIX,
      body,
      options,
    );

    return res.data;
  },
};
