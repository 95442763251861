import axios from "axios";

const axiosConfig = {
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
};

export const proxyAPIGateway = axios.create({
  baseURL: "/api",
  ...axiosConfig,
});

export const apiGateway = axios.create({
  baseURL: process.env.API_URL,
  ...axiosConfig,
});
