import React, { FC } from "react";
import { FieldValues, useForm } from "react-hook-form";

import { GoogleButton, Icon, MuiDialog, TextInput } from "@causevest/ui-kit";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, Typography, capitalize } from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import * as yup from "yup";

import { REQUIRED_MESSAGE } from "@lib/constants";
import inputClasses from "@lib/styles/common.module.scss";
import { FormReturn } from "@lib/types";

import classes from "./AuthForm.module.scss";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: FieldValues) => void;
}

const schema = yup.object().shape({
  email: yup.string().email().required(REQUIRED_MESSAGE),
  display_name: yup.string().required(REQUIRED_MESSAGE),
});

export const SignUpModal: FC<Props> = ({ isOpen, onClose, onSubmit }) => {
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      display_name: "",
    },
  });
  const {
    formState: { errors },
  } = methods;

  const onNextStep = (values: FieldValues) => {
    onSubmit(values);
  };

  const onSocialAuth = useGoogleLogin({
    ux_mode: "redirect",
    redirect_uri: `${process.env.ORIGIN_URL}/auth/callback/google`,
    flow: "auth-code",
  });

  return (
    <MuiDialog
      title="Create Account"
      isOpen={isOpen}
      handleClose={onClose}
      onSubmit={onNextStep}
      methods={methods as unknown as FormReturn}
    >
      <Stack className={classes.form}>
        <Stack className={classes.form__inner}>
          <Stack className={classes.form__social}>
            <Typography variant="h3" className={classes.form__title}>
              Quick Sign-up
            </Typography>
            <GoogleButton onClick={onSocialAuth} />
          </Stack>
          <Stack className={classes.form__body}>
            <Typography variant="h3" className={classes.form__title}>
              Sign up with email instead
            </Typography>
            <Stack className={classes.form__row}>
              <TextInput
                name="email"
                type="email"
                className={inputClasses.input}
                placeholder="Type your email here"
                label="Email"
                error={!!errors.email}
                errorMessage={errors.email?.message ? capitalize(errors.email.message) : undefined}
                required
                fullWidth
              />
              <TextInput
                name="display_name"
                className={inputClasses.input}
                label="Display name"
                placeholder="Type your name to display"
                error={!!errors.display_name}
                errorMessage={
                  errors.display_name?.message ? capitalize(errors.display_name.message) : undefined
                }
                required
                fullWidth
              />
            </Stack>
          </Stack>
          <Stack className={classes.form__reminder}>
            <Icon src="/images/bell.svg" alt="bell" width={25} height={26} />
            <Typography className={classes.form__reminder_text}>
              Use your Google account to quickly create an account. It’s added security, and one
              less password to rememeber.
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </MuiDialog>
  );
};
