import React, { FC } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { CancelButton, GradientButton, MuiDialog, TextInput } from "@causevest/ui-kit";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, capitalize } from "@mui/material";
import { AxiosError } from "axios";
import * as yup from "yup";

import { authAPI } from "@api";

import { handleErrorToast } from "@lib/helpers";
import common from "@lib/styles/common.module.scss";
import { ErrorUnprocessable } from "@lib/types";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const schema = yup.object().shape({
  email: yup.string().email().required("Required field"),
});

export const ResetPasswordModal: FC<Props> = ({ isOpen, onClose }) => {
  const methods = useForm({
    resolver: yupResolver(schema),
  });
  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onChangePassword = async ({ email }: FieldValues) => {
    if (email) {
      try {
        onClose();
        await authAPI.resetPasswordByEmail({ email });

        toast.success(
          "We have sent a message to the email you provided with a link to reset a password. Please check your inbox and follow the instructions. In case an email is not in the inbox, please check the spam folder",
          {
            theme: "colored",
          },
        );
      } catch (err) {
        const error = err as AxiosError;
        handleErrorToast(error.response?.data as ErrorUnprocessable);
      }
      onClose();
    }
  };

  return (
    <MuiDialog title="Reset password" isOpen={isOpen} handleClose={onClose}>
      <FormProvider {...methods}>
        <Stack sx={{ width: "320px", gap: "20px" }}>
          <TextInput
            label="Enter your email"
            name="email"
            type="email"
            className={common.input}
            placeholder="example@example.com"
            errorMessage={errors.email?.message ? capitalize(errors.email.message) : undefined}
            fullWidth
          />
          <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
            <CancelButton onClick={onClose} />
            <GradientButton
              onClick={handleSubmit(onChangePassword)}
              className={common.submitBtn}
              isArrow
            >
              Submit
            </GradientButton>
          </Stack>
        </Stack>
      </FormProvider>
    </MuiDialog>
  );
};
