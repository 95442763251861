"use client";

import { useRouter } from "next/navigation";

import { FC, useState } from "react";
import { FieldValues } from "react-hook-form";
import { toast } from "react-toastify";

import { Preloader } from "@causevest/ui-kit";
import { Stack } from "@mui/material";
import { AxiosError } from "axios";

import { authAPI } from "@api";

import { PasswordsForm } from "@features/auth";

import { handleErrorToast } from "@lib/helpers";
import { AppRoutes, ErrorUnprocessable } from "@lib/types";

import classes from "./ResetPassword.module.scss";

interface Props {
  email: string;
  token: string;
}

export const ResetPassword: FC<Props> = ({ email, token }) => {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);

  const onResetPassword = async ({ password }: FieldValues) => {
    setIsLoading(true);
    try {
      await authAPI.resetPassword({
        email,
        token,
        password,
        password_confirmation: password,
      });

      toast.success("Password reset successfully. You can now Log In with new password.", {
        theme: "colored",
      });

      router.push(AppRoutes.MAIN);
    } catch (err) {
      const error = err as AxiosError;
      handleErrorToast(error.response?.data as ErrorUnprocessable);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Stack justifyContent="space-around" sx={{ minHeight: "calc(100vh - 410px)" }}>
        <Preloader />
      </Stack>
    );
  }

  return (
    <Stack className={classes.wrapper}>
      <PasswordsForm onSubmit={onResetPassword} />
    </Stack>
  );
};
